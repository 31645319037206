/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1680px) {

    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {width: 1170px;}
    .scroll-wrap{height: 408px}

    /* header
    ----------------------------------------------------------------------------------------------*/
    header {
        padding-top: 50px;
        .logo {
            img {
                width: 150px;
                &.stay {width: 100px;}
            }
        }
        .rh_head {
            .main_nav {margin-top: 13px;}
            .burger_mn {height: 70px;width: 70px;}
            .src {
                .ico_src {margin-top: 22px;}
            }
        }
        &.float {
            padding-top: 0 !important;
            .logo {margin-top: 14px; margin-left: 50px;}
            .rh_head {
                .main_nav {margin-top: 14px;}
            }
        }
    }

    .side_mnu {
        .wrap_side {
            /*width: 500px; */padding: 100px 60px 0;
            .btm_side {
                padding: 20px 60px 40px;
                .lang {
                    font-size: 18px;
                    a {font-size: 18px;}
                }
            }
            &:after{background-size: 70px;}

            &:before{background-size: 100% 100%; top: 135px;}
        }
        .close {
            width: 70px; height: 70px;
        }
    }


    /* homepage
    ----------------------------------------------------------------------------------------------*/

    .slideHome {
        .slider {
            .slide {
                .caption {
                    .txt {width: 670px;}
                    h1 {font-size: 30px; line-height: 35px; margin-bottom: 20px;}
                    p {font-size: 16px; line-height: 28px; margin-bottom: 10px;}
                }
            }
        }
    }

    .home_business {
        padding: 110px 0 70px;
        .listing {
            margin: 50px 100px 0;
            .item {
                padding: 55px 45px 50px;height:300px;
                h2 {
                    font-size: 25px; height: 50px; margin-bottom: 10px; overflow: hidden; 
                    span {
                        margin-right:0;
                        &.ico{ background-size: 80% !important; }
                    }
                    label {width: 72%;}
            
                }
                a{
                    &.learn{ font-size: 18px; }
                }
                h6{ font-size: 20px; right: 20px; }
                p {font-size: 16px; line-height: 20px; margin-bottom: 10px;}
                .cover img{width:100%;height: auto;}
            }
        }
    }

    .home_about {
        .text_about {
            width: 470px;z-index:1;
            h2{font-size: 30px;line-height: 34px;}
            p {font-size: 18px; line-height: 26px;}
        }
        .img_about{position: relative;right:-150px;}
    }

    .home_wcu {
        padding-top: 110px;
        .list_wcu {
            margin-top: 50px;
            .col {
                margin-bottom: 20px;
                .img {width: 80px;}
                .text {
                    margin-left: 100px;
                    p {font-size: 18px; line-height: 26px;}
                }
            }
        }
    }
    .home_contact {
        background-position: center;
        .text_contact {
            width: 640px;
            h1 {font-size: 40px; line-height: 50px;}
        }
    }
    
    .service_list {
        margin-top: 20px;
        .item { 
            padding: 15px;
            .txt_item {
                p {font-size: 15px; line-height: 20px;} 
            }
        }
    }
    .content_service {
        padding: 90px 0; height: 100vh;
    }
    .content_market {padding: 90px 0; height: 100vh;}
    .slider_market {margin-top: 15px;}
    .content_galery {
        padding: 90px 0; height: 100vh;
        .slide {
            z-index: 2;
            .img {          
                height: 360px; width: 700px; position: relative;  
                text-align: center;   
                img {
                    height: 100%; max-width: none; display: inline-block;
                    position: absolute; left: 50%;
                    @include transform(translateX(-50%));   
                } 
            }
            .text_slide {
                width: 100%; left: 0; right: 0; padding: 0 70px 0 30px; bottom: 20px;
                @include boxSizing(border-box);
                h6 {font-size: 24px; padding-top: 10px;}
            }
            &.slick-current {z-index: 155;}
        }
        .slider_galery {
            margin-top: 25px; width: 700px;            
        }
    }
   
    .listing{
        &.about_sub {
            .item {
                .dec {padding: 28px 35px;}
            }
        }
    }
    .top_sec{
        h2{ font-size: 22px; line-height: 24px; } 
        p {font-size: 18px; line-height: 24px;}
    }
    .title{
        margin-bottom: 25px;
    }

    .link{
        .btn{ margin-top: 18px; }
    }

    /* middle
    ----------------------------------------------------------------------------------------------*/
    .middle {
        padding-bottom: 180px; background-size: 100%;
    }
    .pg_title {
        .txt_title_pg {
            width: 970px;
            h2 {font-size: 40px;line-height: 48px;}
            p {font-size: 18px; line-height: 26px;}
            h3 {font-size: 60px; margin-bottom: 30px;}
        }
    }
    .banner_pg {
        .images {margin-top: -20%;}
    }
    .inner_text {
        padding: 50px;margin-bottom: 50px;
        > h2 {font-size: 40px; margin-bottom: 60px;}
        &:first-of-type {
            padding-top: 50px;
        }
        .lf_txt {
            width: 360px;
            .sub_carrer {
                ul {
                    li {
                        a {font-size: 22px;}
                    }
                }
            }
        }
        .rh_txt {
            width: 680px;
            p {font-size: 18px; line-height: 30px;}
            ul {
                li {padding-left: 30px; font-size: 18px; line-height: 28px;}
            }
        }
        &.contact {
            .lf_txt {
                h3 {padding-right: 45px;}
            }
        }
        .client-list{
            a{
                margin:0 70px 0 0;
            }
        }
    }
    #map_contact {height: 500px;}
    .slider_market .slide {
        .dec{
            padding:20px;
            h6 {font-size: 16px;}
        }
        &:hover{
            .dec .hov{height: 220px;}
        }
    }
    .form_contact {
        .row {
            .field {
                input[type="text"],
                input[type="number"],
                input[type="email"],
                select, textarea {
                    + span {font-size: 18px;}
                }
            }
        }
    }
    .content_client {
        .list_client {
            .item {padding: 15px 25px;}
        }
    }

    .wrap-popup{
        .box-popup{
            width:800px;
            .slider-1 {
                .slick-arrow {top: -20px;}
                .slick-arrow.slick-prev{
                    left:-50px;
                    &:hover{left: -55px}                    
                }
                .slick-arrow.slick-next{
                    right:-50px;
                    &:hover{right: -55px}                    
                }
                .img {margin: 0 0 30px 0;}
                .text {
                    h6 {font-size: 24px;}
                }
            }
            .slider-2{
                top: 420px;left:-50px;right:-50px;
                .box {height: 100px;}
            }
        }
    }

    .pg_title{
        .map{
            width: 1300px; left: 0px;height:647px;
            .pointer{
                width: 120px; font-size: 14px;  height: 40px; line-height: 40px;
                .box{ width: 120px; height: 40px; }
                &.p-1{ left: 600px;top:195px; }
                &.p-2{ left: 850px; top: 130px; }
                &.p-3{ 
                    left: 940px; top: 190px; width: 140px;
                    .box{ width: 140px; }
                 }
                &.p-4{ left: 1050px;  top:265px}
                &.p-5{ left: 680px;  top:120px;}
                
            }
        }
    }

    .content_galery{
        .slick-arrow{
            &.slick-prev{ left: -50px; }
            &.slick-next{ right: -50px; }
        }
    }   
    .scrollify{
        &.novh{ height: 70vh; min-height: 440px; }
    }
    

    /* footer
    ----------------------------------------------------------------------------------------------*/
    footer{
        height: auto !important;
    }



}